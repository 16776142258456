var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-card',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('a-row',{attrs:{"type":"flex"}},[_c('a-col',{attrs:{"span":24,"md":12}},[_c('h6',[_vm._v("Employees")]),_c('p',[_vm._v(" Total "),_c('span',{staticClass:"text-primary"},[_vm._v(_vm._s(_vm.employees.length))])])]),_c('a-col',{staticStyle:{"display":"flex","align-items":"center","justify-content":"flex-end"},attrs:{"span":24,"md":12}},[_c('a-radio-group',{attrs:{"size":"small"}},[_c('a-button',{attrs:{"type":"primary"},on:{"click":_vm.openDrawer}},[_vm._v(" Add New Employee ")])],1),_c('a-radio-group',{staticClass:"mx-5",attrs:{"size":"small"}},[_c('a-button',{on:{"click":_vm.exportData}},[_vm._v("Export Employee Data")])],1)],1)],1)]},proxy:true}])},[_c('a-drawer',{attrs:{"title":"Add a new Employee","width":720,"visible":_vm.visible,"body-style":{ paddingBottom: '80px' }},on:{"close":_vm.handleCancel}},[_c('create-employee',{attrs:{"client":_vm.client}}),_c('div',{style:({
        position: 'absolute',
        right: 0,
        bottom: 0,
        width: '100%',
        borderTop: '1px solid #e9e9e9',
        padding: '10px 16px',
        background: '#fff',
        textAlign: 'right',
        zIndex: 1,
      })},[_c('a-button',{style:({ marginRight: '8px' }),on:{"click":_vm.handleCancel}},[_vm._v(" Cancel ")])],1)],1),_c('a-modal',{attrs:{"title":"Bulk Imports"},model:{value:(_vm.modal),callback:function ($$v) {_vm.modal=$$v},expression:"modal"}},[_c('template',{slot:"footer"},[_c('a-button',{key:"back",on:{"click":_vm.handleCancel}},[_vm._v(" Cancel ")]),_c('a-button',{key:"submit",attrs:{"type":"primary","loading":_vm.loading},on:{"click":_vm.parseFile}},[_vm._v(" Upload ")])],1),_c('div',{staticClass:"table-upload-btn"},[_c('a-button',{attrs:{"type":"dashed","block":""},on:{"click":function($event){$event.preventDefault();return _vm.exportExcel.apply(null, arguments)}}},[_c('a-icon',{attrs:{"type":"download"}}),_vm._v(" Download Sample CSV ")],1)],1),_c('a-input',{attrs:{"type":"file","accept":".csv"},on:{"change":function($event){return _vm.handleUpload($event)}}})],2),_c('a-table',{attrs:{"columns":_vm.columns,"data-source":_vm.data,"bordered":"","scroll":{ x: 2000 },"rowKey":"id"},scopedSlots:_vm._u([_vm._l(([
        'first_name',
        'last_name',
        'address',
        'email',
        'phone_number',
        'gender',
        'kra_pin',
        'department',
        'nssf',
        'nhif',
        'basicpay',
        'designation' ]),function(col){return {key:col,fn:function(text, record, index){return [_c('div',{key:col},[(record.editable)?_c('a-input',{staticStyle:{"margin":"-5px 0"},attrs:{"value":text},on:{"change":function (e) { return _vm.handleChange(e.target.value, record.id, col); }}}):[_vm._v(" "+_vm._s(text)+" ")]],2)]}}}),{key:"operation",fn:function(text, record, index){return [_c('div',{staticClass:"editable-row-operations"},[(record.editable)?_c('span',[_c('a',{on:{"click":function () { return _vm.save(record.id); }}},[_vm._v("Save")]),_c('a-popconfirm',{attrs:{"title":"Sure to cancel?"},on:{"confirm":function () { return _vm.cancel(record.id); }}},[_c('a',[_vm._v("Cancel")])])],1):_c('span',[_c('a',{attrs:{"disabled":_vm.editingKey !== ''},on:{"click":function () { return _vm.edit(record.id); }}},[_vm._v("Quick Edit")])])])]}},{key:"view",fn:function(text, record, index){return [_c('div',{staticClass:"editable-row-operations"},[_c('span',[_c('a',{on:{"click":function () {
                _vm.viewEmployee(record);
              }}},[_vm._v("View")])])])]}}],null,true)}),_c('div',{staticClass:"table-upload-btn"},[_c('a-button',{attrs:{"type":"dashed","block":""},on:{"click":_vm.showDrawer}},[_c('svg',{attrs:{"width":"16","height":"16","viewBox":"0 0 20 20","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"fill-rule":"evenodd","clip-rule":"evenodd","d":"M3 17C3 16.4477 3.44772 16 4 16H16C16.5523 16 17 16.4477 17 17C17 17.5523 16.5523 18 16 18H4C3.44772 18 3 17.5523 3 17ZM6.29289 6.70711C5.90237 6.31658 5.90237 5.68342 6.29289 5.29289L9.29289 2.29289C9.48043 2.10536 9.73478 2 10 2C10.2652 2 10.5196 2.10536 10.7071 2.29289L13.7071 5.29289C14.0976 5.68342 14.0976 6.31658 13.7071 6.70711C13.3166 7.09763 12.6834 7.09763 12.2929 6.70711L11 5.41421L11 13C11 13.5523 10.5523 14 10 14C9.44771 14 9 13.5523 9 13L9 5.41421L7.70711 6.70711C7.31658 7.09763 6.68342 7.09763 6.29289 6.70711Z","fill":"#111827"}})]),_vm._v(" import CSV ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }